<template>
    <div v-if="isActiveTest" :class="$style.footerContainer">
        Во время прохождения теста не обновляйте страницу и не переходите на другие вкладки.<br >
        Если уйдёте с этой страницы, тестирование завершится автоматически. <br >
        Пройти тест заново можно будет через 24 часа
    </div>
</template>

<script setup lang="ts">
const props = defineProps({
  testActive: {
    type: Boolean,
    default: false
  }
})

const isActiveTest = computed(() => {
  return props.testActive
})
</script>

<style module lang="scss" scoped>
.footerContainer {
  background: var(--common-fog);
  width: 100%;
  color: var(--common-asphalt);
  padding-bottom: 38px;
  text-align: center;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}
</style>