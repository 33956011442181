<template>
  <header :class="$style.header">
    <LogoDark />
    <div :class="$style.headerUserContainer">
      <div :class="$style.headerUserName"> {{ state.userName }}</div>
      <Profile />
    </div>
  </header>
</template>

<script setup lang="ts">
import LogoDark from "assets/icons/LogoDark.svg?component";
import Profile from "assets/icons/Profile.svg?component"
import { useAuthStore } from '~/store/auth.store';

const authStore = useAuthStore()

const state = reactive({
  userName: authStore.user?.firstName + ' ' + authStore.user?.lastName
})
</script>

<style module lang="scss">
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 32px;
}

.headerUserContainer {
  display: inline-flex;
  align-items: center;  
  gap: 12px;
}

.headerUserName {
  max-width: 150px;
  white-space: nowrap;
  overflow: hidden;
  color: var(--primary);
  text-overflow: ellipsis;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}
</style>
