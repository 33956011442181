<template>
  <div :class="$style.contentContainer">
    <TheHeader />
    <div :class="$style.mainContent">
      <NuxtPage />
    </div>
    <TheFooter :test-active="sessionStatus === SessionStatus.IN_PROGRESS"/>
  </div>
</template>

<script setup lang="ts">
import TheFooter from "~/components/layouts/TheFooter.vue";
import TheHeader from "~/components/layouts/TheHeader.vue";
import { useSessionStore } from "~/store/session.store";
import { SessionStatus } from "~/types/sessionStatus";
const sessionStore = useSessionStore()
const sessionStatus = computed(() => {
  return sessionStore.sessionStatus
})
</script>

<style module lang="scss">
body {
  background: var(--common-fog);
}

.mainContent {
  flex: 1;
}

.contentContainer {
  display: flex;
  flex-direction: column;
  height: 100vh; 
}
</style>
